import { EmptyProps } from "@/definitions";
import React from "react";

const Footer: React.FC<EmptyProps> = () => {
  const today = new Date();

  return (
    <footer className="flex flex-wrap justify-between w-full lg:text-left mt-20 border-t py-10 border-color-navborder">
      <div className="text-sm text-gray-700 dark:text-gray-400 px-6">
        <a className="text-blue-500 dark:text-blue-200 font-semibold" href="mailto:katili.jiwo.work@gmail.com">katili.jiwo.work@gmail.com</a> <br />
        <span className="font-normal">Jakarta, Indonesia</span>
      </div>
      <div className="text-sm font-medium float-right text-gray-700 dark:text-gray-400 px-6 mt-4 md:mt-0">
        © {today.getFullYear()} Katili Jiwo Adi W.
      </div>
    </footer>

  );
};

export default Footer;

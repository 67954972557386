import { ChecklistItem } from "@/constans";
import emptySvg from "assets/emptysvg.svg";
import React from "react";

const PortfolioDialog: React.FC<IPortfolioDialog> = (prop: IPortfolioDialog) => {
    return <>
        <div className="fixed z-50 overflow-y-auto inset-0 h-full backdrop-blur-sm">
            <div className="relative md:pt-28 pt-4 md:w-1/2 w-11/12 mx-auto md:h-full">
                <div className="relative bg-white rounded-lg shadow dark:bg-gray-800 px-1">
                    <div className="flex items-center justify-between p-4 md:p-5 rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            {prop.checkListItems?.app}
                        </h3>
                        <button onClick={prop.onDimissDialog} className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                            <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="mx-4">
                        {
                            prop.checkListItems?.img
                                ? <img src={prop.checkListItems?.img} alt={prop.checkListItems?.img} className="mx-auto mt-4 w-auto md:h-52 h-32 object-cover rounded-lg" />
                                : <div>
                                    <img src={emptySvg} alt={prop.checkListItems?.img} className="mx-auto mt-4 w-auto md:h-44 h-28 object-cover rounded-lg" />
                                    <p className="text-center mt-2 text-gray-500 dark:text-gray-200">No Image Preview</p>
                                </div>
                        }
                    </div>
                    <div className="p-4 md:p-5 space-y-4 ">
                        <p className="text-gray-500 dark:text-gray-200 md:text-base text-sm">
                            {prop.checkListItems?.content}
                        </p>
                        <p className="text-base text-blue-500 dark:text-blue-400">
                            <span className="text-gray-500 dark:text-gray-200 md:text-base text-sm">Link:</span> {prop?.checkListItems?.link ? <a href={prop?.checkListItems?.link} target="_blank"> application link</a> : <span className="text-gray-500 dark:text-gray-200">-</span>}
                        </p>
                        <div className="flex flex-wrap gap-2">
                            <span className="text-gray-500 dark:text-gray-200 md:text-base text-sm"> The stack that I use are: </span>
                            {prop.checkListItems?.chip.map((itm, idx) => (
                                <div key={idx} className="text-xs relative grid select-none items-center whitespace-nowrap rounded-full py-1.5 px-3 font-sans font-bold bg-blue-900 text-blue-200 dark:text-blue-300">
                                    {itm}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

interface IPortfolioDialog {
    onDimissDialog: () => void,
    checkListItems: ChecklistItem | null
}

export default PortfolioDialog
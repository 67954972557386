import { EmptyProps } from "@/definitions";
import React from "react";

const HeroSection: React.FC<EmptyProps> = () => {
    return (
        <div className="flex flex-col item-center mt-6 lg:mt-19">

            <div className="text-center tracking-wide text-white">
                <h1 className="mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl"><span className="text-transparent bg-clip-text bg-gradient-to-r from-skin-sky to-skin-emerald">Hello welcome</span> to my site 👋</h1>

                <br />

                <span className='text-sm md:text-lg lg:text-xl text-neutral-500 text-gray-700 dark:text-white'>
                    {" "} My name is <span className='text-sm md:text-lg lg:text-xl text-primary font-medium'>Katili Jiwo Adi Wiyono</span >, I am a software engineer based in Indonesia. <br />
                    I work as a <span className='text-sm md:text-lg lg:text-xl text-primary font-medium'> Native & Hybrid Mobile Developer</span>, also I like to learn <span className='text-sm md:text-lg lg:text-xl text-primary font-medium'>React Web Development and Artificial Intelligence</span> <br />
                    In mean time I write some tech posts in this <a href="/blog" className="text-blue-500 font-medium">Blog Page</a>, and build some stuffs in my <a href="https://github.com/jiwomdf" className="text-blue-500 font-medium">Github</a>. <br />
                    <br />
                    Thank you for visiting ✌️
                </span>
            </div>
        </div>
    )
}

export default HeroSection
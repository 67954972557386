
import Certificates from "@/components/page/Certificates";
import Footer from "@/components/page/Footer";
import HeroSection from "@/components/page/HeroSection";
import Navbar from "@/components/page/Navbar";
import Portfolio from "@/components/page/Portfolio";
import { PageProps } from "gatsby";
import React from "react";

const Index: React.FC<PageProps> = ({ }) => {
  return (
    <>
      <Navbar />
      <div className="max-w-7xl mx-auto pt-20 px-6 bg-radial-gradient-bg">
        <HeroSection />
        <Certificates />
        <Portfolio />
        <Footer />
      </div>
    </>
  );
}

export default Index